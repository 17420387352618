<template>
    <div>
        <header-menu ref="HeaderMenu"></header-menu>
        <div class="view-content">
            <div class="banner">
                <div class="desc__box text_left">
                    <h1 class="banner_title b_b_space font-size_60">
                        向日葵•结算系统
                    </h1>
                    <p class="banner_desc b_b_space font-size_20">
                        产品描述描述描述述描述述描述述描述述述描述述述描述述述述描述述述描述述述述描述述述描述产品描述描述描述述描述述描述述描述述述描述述述描述述述述描述述述描述述述述描述述述描述产品描述描述描述述描述述描述述描述述述描述述述
                    </p>
                </div>
            </div>
            <div class="l_r_space t__space">
                <h2 class="nav__title">核心功能</h2>
                <div class="tab_Jurisdiction">
                    <a-tabs default-active-key="1">
                        <a-tab-pane
                            key="1"
                            tab="XXXXX"
                            :tabBarStyle="{
                                borderBottom: 'none',
                            }"
                        >
                            <a-row
                                :gutter="80"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                >
                                    <img
                                        style="max-width: 360px"
                                        src="@/assets/img/png/bg_default.png"
                                        alt="XXXXX"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">XXXXX</p>
                                    <div class="Jurisdiction_desc">
                                        <p>
                                            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        </p>
                                        <p>
                                            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        </p>
                                        <p>
                                            xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                                        </p>
                                    </div>
                                </a-col>
                            </a-row>
                        </a-tab-pane>
                        <a-tab-pane key="2" tab="平台整合">
                            <a-row
                                :gutter="80"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                >
                                    <img
                                        style="max-width: 360px"
                                        src="@/assets/img/png/bg_default.png"
                                        alt="平台整合"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">平台整合</p>
                                    <div class="Jurisdiction_desc">
                                        <p>
                                            与转折医药营销行为管理平台“销讯通”数据互通，满足医药企业人员绩效、营销管理、日常行为、合规证据链的全方位解决方案
                                        </p>
                                    </div>
                                </a-col>
                            </a-row></a-tab-pane
                        >
                        <a-tab-pane key="3" tab="业务层级多元化">
                            <a-row
                                :gutter="80"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                >
                                    <img
                                        style="max-width: 360px"
                                        src="@/assets/img/png/bg_default.png"
                                        alt="业务层级多元化"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">
                                        业务层级多元化
                                    </p>
                                    <div class="Jurisdiction_desc">
                                        <p>
                                            医药企业（工业端）--CSP/综合服务商（综合服务商端）--项目服务商（项目服务商端）--业务代表（个人端）4个业务层级，可拆分、组合使用
                                        </p>
                                        <p>
                                            工业对CSP的管理，服务任务下达，任务分配
                                        </p>
                                        <p>
                                            CSP对下游项目服务商的管理，任务分解，收集证据以及审核
                                        </p>
                                        <p>
                                            项目服务商对代表人员的管理，合规证据链的收集
                                        </p>
                                    </div>
                                </a-col>
                            </a-row></a-tab-pane
                        >
                        <a-tab-pane key="4" tab="多上游业务场景">
                            <a-row
                                :gutter="80"
                                type="flex"
                                class="row_view-box"
                            >
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                >
                                    <img
                                        style="max-width: 360px"
                                        src="@/assets/img/png/bg_default.png"
                                        alt="多上游业务场景"
                                        class="view_width-full"
                                    />
                                </a-col>
                                <a-col
                                    :xs="24"
                                    :sm="24"
                                    :md="12"
                                    :lg="12"
                                    :xl="12"
                                    :xxl="12"
                                    class="text_left"
                                >
                                    <p class="Jurisdiction_title">
                                        多上游业务场景
                                    </p>

                                    <div class="Jurisdiction_desc">
                                        <p>
                                            针对CSP机构、综合服务商一级项目服务商，“芒哥”均支持多个上游企业；CSP/综合服务商可同时签订多家合规证据链的年度任务
                                        </p>
                                    </div>
                                </a-col>
                            </a-row></a-tab-pane
                        >
                    </a-tabs>
                </div>
            </div>
            <div class="l_r_space t__space" style="background-color: #eee">
                <h2 class="nav__title">为什么选择正也科技</h2>
                <h3 class="nav__desc" style="padding: 0.1rem 0 0.4rem 0">
                    行业有深度 数据有态度
                </h3>
                <div>
                    <a-row :gutter="20" type="flex">
                        <a-col
                            style="padding: 0 0.1rem 20px 0.1rem"
                            :xs="24"
                            :sm="12"
                            :md="12"
                            :lg="8"
                            :xl="8"
                            :xxl="8"
                            v-for="(item, index) in softWareList"
                            :key="index"
                        >
                            <a-card>
                                <a-row style="align-items: center" type="flex">
                                    <a-col
                                        :xs="24"
                                        :sm="10"
                                        :md="10"
                                        :lg="10"
                                        :xl="10"
                                        :xxl="10"
                                        ><img
                                            :src="item.img_url"
                                            :alt="item.title"
                                            style="width: 100%; max-width: 80px"
                                    /></a-col>
                                    <a-col
                                        :xs="24"
                                        :sm="14"
                                        :md="14"
                                        :lg="14"
                                        :xl="14"
                                        :xxl="14"
                                        ><div style="min-height: 1.1rem">
                                            <h3
                                                class="soft_title text_left b_b_space font-size_26"
                                            >
                                                {{ item.title }}
                                            </h3>
                                            <p
                                                class="soft_desc text_left b_b_space font-size_16"
                                            >
                                                {{ item.desc }}
                                            </p>
                                        </div></a-col
                                    >
                                </a-row>
                            </a-card>
                        </a-col>
                    </a-row>
                </div>
            </div>
        </div>
        <Footer></Footer>
    </div>
</template>
<script>
import Footer from '@/components/Footer'
import HeaderMenu from '@/components/HeaderMenu'
import soft_1 from '@/assets/img/png/soft_1.png'
import soft_2 from '@/assets/img/png/soft_2.png'
import soft_3 from '@/assets/img/png/soft_3.png'
import soft_4 from '@/assets/img/png/soft_4.png'
import soft_5 from '@/assets/img/png/soft_5.png'
import soft_6 from '@/assets/img/png/soft_6.png'
export default {
    name: 'sunflower',
    beforeCreate() {},
    components: { Footer, HeaderMenu },
    data() {
        return {
            softWareList: [
                {
                    title: '行业深度',
                    desc:
                        '正也科技核心成员为医药营销管理咨询专家和多年医药行业软件开发实施经验。',
                    img_url: soft_1,
                },
                {
                    title: '资源丰富',
                    desc:
                        '公司拥有海量行业主数据供客户选择，以及丰富的关键管理识别经验',
                    img_url: soft_2,
                },
                {
                    title: '产品规范',
                    desc:
                        'S2P支持全面数据颗粒化、追求灵活配置，应对企业未来管理需求',
                    img_url: soft_3,
                },
                {
                    title: '敏捷开发',
                    desc:
                        '基于主数据平台深度颗粒化，不断快速迭代，满足企业的个性化需求',
                    img_url: soft_4,
                },
                {
                    title: '致力创新',
                    desc:
                        'S2P专注于研究“营销方法论”、“营销新算法”，“让数据有态度”',
                    img_url: soft_6,
                },
                // {
                //   title: "致力于创新",
                //   desc: "公司专注于研究如何使用信息化的方法，以“数”助销",
                //   img_url: soft_6,
                // },
            ],
        }
    },
    created() {},
    methods: {
        handleSubmit() {},
    },
}
</script>
<style scoped lang="scss">
@import '@/assets/style/color.scss';
.soft_title {
    padding: 0.15rem 0 0.1rem 0;
}
.soft_desc {
    color: $color-fixGray;
}

.banner {
    width: 100%;
    position: relative;
    background: url('~@/assets/img/png/sunflower_banner.png');
    background-size: 100% 100%;
    height: 8rem;
    overflow: hidden;
    .desc__box {
        position: absolute;
        top: 1.2rem;
        left: 13.5%;
        .banner_desc {
            width: 4.9rem;
            padding-top: 0.3rem;
        }
    }
}
.row_view-box {
    align-items: center;
    padding: 0rem 0 0.8rem 0;
}
</style>
